import { parseText } from "@/utilities/htmlParser";
import { Grid } from "@hiltermann/components/ui";
import PageHeaderCircle from "./PageHeader/PageHeaderCircle";
import { ActionCardGrid } from "./ActionCardGrid";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";

export type PageHeaderProps = GetDynamicContentItemProps<"PageHeader">;

const PageHeader: React.FC<PageHeaderProps> = ({
  background_image,
  title,
  subtitle,
  description,
  className,
  action_cards,
  show_circle,
}) => {
  const backgroundStyle = {
    backgroundImage: `linear-gradient(to right, #7C394A 0%, rgba(0,0,0,0.7) 40%, transparent 65%, transparent), url(/_next/image?url=${background_image?.[0]?.default}&w=2048&q=95)`,
  };
  return (
    <div className={className}>
      <header
        data-block="PageHeader"
        className={`from-secondary-aubergine to-primary-emerald tablet:min-h-[54rem] tablet:bg-center flex min-h-[39rem] justify-center overflow-hidden via-black bg-cover bg-[position:right_0_top_0,_right_-10rem_top_0rem] pb-24`}
        style={backgroundStyle}
      >
        <Grid className="relative z-0 h-full" containerClass="w-full">
          {show_circle && <PageHeaderCircle />}
          <div className="tablet:col-span-7 tablet:mt-40 tablet:mb-32 col-span-full mb-12 mt-60 text-white">
            <p className="text-p text-primary-mint tablet:text-intro font-serif tracking-wider">
              {title}
            </p>
            <h1 className="text-h1-sm tablet:mt-6 tablet:text-h1 mt-2 font-bold tracking-wider">
              {subtitle}
            </h1>
            {description && (
              <div className="text-p tablet:mt-10 tablet:text-intro mt-4 font-serif tracking-wider">
                {parseText(description)}
              </div>
            )}
          </div>
        </Grid>
      </header>
      {action_cards?.[0] && action_cards?.[1] && (
        <ActionCardGrid
          className="tablet:-mt-48 -mt-28"
          cardOneProps={action_cards[0]}
          cardTwoProps={action_cards[1]}
        />
      )}
    </div>
  );
};

export default PageHeader;
