import React, { useState } from "react";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import TextLink from "@/components/shared/TextLink";
import { Grid } from "@hiltermann/components/ui";
import { InformationTabsBlockProps } from "../InformationTabsBlock";
import TabContent from "./InformationTabsBlockDesktop/TabContent";
import TabSelector from "./InformationTabsBlockDesktop/TabSelector";

const InformationTabsBlockDesktop: React.FC<InformationTabsBlockProps> = ({
  title,
  description,
  tabs,
  button_text,
  button_target,
}) => {
  const { type, href, onClick, isValid } = useButtonTarget(button_target[0]);
  const [selectedTabTitle, setSelectedTabTitle] = useState(tabs?.[0]?.title);
  const selectedTab = tabs?.find((tab) => tab.title === selectedTabTitle);
  const onClickTab = (tabTitle: string) => () => setSelectedTabTitle(tabTitle);

  return (
    <Grid>
      {title && (
        <h2 className="text-h3 col-span-8 col-start-3 row-start-1 mb-10 text-center font-bold tracking-wider">
          {title}
        </h2>
      )}
      {description && (
        <p className="text-p col-span-6 col-start-4 row-start-2 mb-20 text-center font-serif tracking-wider">
          {description}
        </p>
      )}
      <div className="col-span-8 col-start-3 row-start-3 h-full bg-white" />
      <div className="col-span-4 col-start-2 row-start-3 bg-white">
        <ul className="mt-5">
          {tabs?.map((tab, i) => {
            if (!tab || !tab.title) return null;
            return (
              <TabSelector
                key={i}
                tabTitle={tab.title}
                selectedTabTitle={selectedTabTitle}
                onClickTab={onClickTab}
              />
            );
          })}
        </ul>
        <div className="mx-12 pb-16 pt-12">
          {button_text && isValid && (
            <TextLink
              variant="secondary"
              type={type}
              href={href}
              onClick={onClick}
              className="inline-flex"
            >
              {button_text}
            </TextLink>
          )}
        </div>
      </div>
      <div className="shadow-card col-span-6 col-start-6 row-start-3 bg-white p-16 pb-20">
        {selectedTab && <TabContent {...selectedTab} />}
      </div>
    </Grid>
  );
};

export default InformationTabsBlockDesktop;
