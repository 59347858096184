export const acceptedFileTypes = {
  document: {
    pdf: { "application/pdf": [".pdf"] },
  },
  image: {
    jpeg: { "image/jpeg": [".jpeg", ".jpg"] },
    png: { "image/png": [".png"] },
    gif: { "image/gif": [".gif"] },
  },
};
