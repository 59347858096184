import React, { useState } from "react";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Pagination } from "swiper/modules";
import Link from "next/link";
import "swiper/css";
import "swiper/css/pagination";
import { parseQuoteDark } from "@/utilities/htmlParser";
import PreviewCard from "@/components/shared/PreviewCard/PreviewCard";
import CardImage from "@/components/shared/Card/CardImage";
import { Button } from "@hiltermann/components/form";
import { EmployeeExperienceCarouselProps } from "../EmployeeExperienceCarousel";
import CarouselButtons from "./EmployeeExperienceCarouselDesktop/CarouselButtons";

interface EmployeeExperienceCarouselDesktopProps {
  employeeStories: EmployeeExperienceCarouselProps["employee_stories"];
  cardButtonText: EmployeeExperienceCarouselProps["card_button_text"];
}

const EmployeeExperienceCarouselDesktop: React.FC<EmployeeExperienceCarouselDesktopProps> = ({
  employeeStories,
  cardButtonText,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(2);

  const initialSlide = employeeStories?.length ? Math.ceil(employeeStories.length / 2) - 1 : 0;

  const onActiveIndexChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={32}
      pagination={{
        clickable: true,
        bulletClass: "hidden",
      }}
      modules={[Pagination]}
      centeredSlides
      onSlideChange={onActiveIndexChange}
      initialSlide={initialSlide}
      className="mt-20"
    >
      {employeeStories?.map(({ quote: customQuote, items }, index) => {
        const employeeStory = items?.[0];
        const isActiveSlide = index === activeIndex;
        const { _slug, caption, image, quote: defaultQuote } = employeeStory ?? {};
        const quote = customQuote ?? defaultQuote;
        if (!_slug || !caption || !quote || !image?.[0]?.default) return null;

        const CardContent = (
          <PreviewCard
            hoverEffectDisabled={!isActiveSlide}
            darkThemeEnabled
            className="h-[582px] w-[448px]"
          >
            <CardImage imageUri={image?.[0]?.default} imageDescription={image?.[0]?.description} />
            <div className="flex flex-1 flex-col justify-between px-14 py-12">
              <div>
                {caption}
                <p className="text-h4-sm tablet:mt-6 tablet:text-h3-sm mt-4 font-sans font-bold tracking-wider">
                  {parseQuoteDark(quote)}
                </p>
              </div>
              <Button
                className="tablet:mt-8 mt-6 self-start"
                variant="tertiary"
                size="small"
                type="div"
              >
                {cardButtonText ? cardButtonText : ""}
              </Button>
            </div>
          </PreviewCard>
        );

        return (
          <SwiperSlide key={index} className="!flex !h-auto min-w-[232px] max-w-[448px]">
            {_slug ? <Link href={_slug}>{CardContent}</Link> : CardContent}
            <div
              className={`bg-primary-black pointer-events-none absolute inset-0 z-10 transition-opacity duration-500 ease-in-out ${
                isActiveSlide ? "opacity-0" : "opacity-80"
              }`}
            />
          </SwiperSlide>
        );
      })}
      <CarouselButtons activeIndex={activeIndex} />
    </Swiper>
  );
};

export default EmployeeExperienceCarouselDesktop;
