"use client";

import type { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Accept, FileWithPath } from "react-dropzone";
import { UnControlledFileUpload, type Texts } from "./UnControlledFileUpload";

interface ControlledFileUploadProps {
  acceptedFileTypes?: Accept;
  name: string;
  texts: Texts;
  disabled?: boolean;
  error?: string;
  maxFiles?: number;
  errorFormatter?: (value: string) => string;
}

export const ControlledFileUpload: FC<ControlledFileUploadProps> = ({
  acceptedFileTypes,
  disabled,
  error,
  maxFiles,
  name,
  texts,
  errorFormatter,
}) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const errorMessage = errors[name] ? String(errors[name]?.message) : error || "";
  const formattedError =
    errorFormatter && errorMessage !== "" ? errorFormatter(errorMessage) : errorMessage;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <UnControlledFileUpload
          acceptedFileTypes={acceptedFileTypes}
          disabled={disabled}
          error={formattedError}
          files={field.value}
          maxFiles={maxFiles}
          name={field.name}
          onFilesChange={field.onChange}
          onRemoveFile={(file) => {
            field.onChange(field.value.filter((f: FileWithPath) => f.path !== file.path));
          }}
          texts={texts}
        />
      )}
    />
  );
};
