import { FC, ReactNode } from "react";
import clsx from "clsx";
import { Grid } from "../Grid/Grid";

export interface HeroTexts {
  title: string | ReactNode;
  subtitleBefore?: string;
  subtitleAfter?: string;
  text?: string;
}

export interface HeroProps {
  texts: HeroTexts;
  children?: ReactNode;
  className?: string;
}

export const Hero: FC<HeroProps> = ({ texts, className, children }) => (
  <section>
    <Grid containerClass="bg-gradient-1 desktop-s:min-h-[382px] mobile:min-h-[220px] tablet:min-h-[206px] relative flex text-white pt-8 tablet:pt-10 desktop-s:pt-24">
      <div className="text-white-1 tablet:col-span-12 desktop-s:col-span-12 tablet:pb-14 col-span-4 flex w-full justify-center pb-12">
        <div className="desktop-s:justify-around relative z-10 flex size-full ">
          <div className={clsx("w-full", className)}>
            <div className="max-w-[928px]">
              {texts.subtitleBefore && (
                <p className="desktop-s:text-xl font-sans font-bold">
                  {texts.subtitleBefore}
                  {texts.subtitleAfter && (
                    <span className="desktop-s:text-base mobile:text-xs ml-2 font-serif font-extralight">
                      {texts.subtitleAfter}
                    </span>
                  )}
                </p>
              )}
              <h1
                className={clsx(
                  "mobile:text-2xl desktop-s:text-4xl font-sans font-bold",
                  texts.subtitleBefore && "mt-4"
                )}
              >
                {texts.title}
              </h1>
              {texts.text && (
                <p className="desktop-s:text-xl mobile:text-base mt-4 font-serif text-lg font-normal">
                  {texts.text}
                </p>
              )}
            </div>
            {children}
          </div>
        </div>
        <svg
          className="desktop-s:block absolute bottom-[-145px] right-36 z-0 hidden"
          width={447}
          viewBox="0 0 447 447"
          fill={"currentColor"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_5650_17397"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="447"
            height="447"
          >
            <g opacity="0.1" clipPath="url(#clip0_5650_17397)">
              <path
                d="M223.744 0C283.035 0.039784 339.881 23.6252 381.778 65.5677C423.675 107.51 447.19 164.374 447.15 223.65C447.111 282.926 423.519 339.758 381.566 381.645C339.613 423.531 282.735 447.04 223.444 447C99.0251 447.225 -0.149868 346.838 0.000170014 220.8C0.0376795 98.1375 99.6253 0 223.744 0ZM223.069 16.725C109.453 16.725 16.7669 108.675 16.7669 221.513C16.4774 276.533 38.0397 329.421 76.7194 368.562C115.399 407.703 168.035 429.898 223.069 430.275C335.597 431.475 430.984 336.038 430.721 222.525C430.459 109.013 336.985 16.725 223.069 16.725Z"
                fill="white"
              />
              <path
                d="M276.256 231.637H174.981V342.862H145.573V325.725C145.573 272.287 145.573 218.85 145.573 165.412C145.573 133.387 141.11 128.4 114.365 126.975C113.315 126.975 112.302 125.662 107.389 122.287L174.043 113.25V216.637H275.318V114.337H303.863V342.788H276.294L276.256 231.637Z"
                fill="white"
              />
            </g>
          </mask>
          <g mask="url(#mask0_5650_17397)">
            <rect width="447" height="308" fill="#D9D9D9" />
          </g>
          <defs>
            <clipPath id="clip0_5650_17397">
              <rect width="447" height="447" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </Grid>
  </section>
);
