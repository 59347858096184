import React from "react";
import * as Sentry from "@sentry/nextjs";
import { Button } from "@hiltermann/components/form";
import { Grid } from "@hiltermann/components/ui";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { useButtonTarget } from "@/hooks/useButtonTarget";

type ButtonBlockProps = GetDynamicContentItemProps<"ButtonBlock">;

const ButtonBlock: React.FC<ButtonBlockProps> = ({
  text,
  target,
  variant = "light",
  className,
}) => {
  const { type, href, onClick, isValid } = useButtonTarget(target && target[0]);

  if (!text || !isValid) {
    Sentry.captureException(
      new Error(
        `Content Issue: ButtonBlock "${text}" is invalid. Possibly there is not valid target specified in Prepr?`
      )
    );
    return null;
  }
  return (
    <Grid dataBlock="ButtonBlock" className={`relative ${className ?? ""}`}>
      <div className="tablet:col-span-10 tablet:col-start-3 col-span-full">
        <Button
          type={type}
          variant={variant === "light" ? "secondary" : "tertiary"}
          href={href}
          onClick={onClick}
        >
          {text}
        </Button>
      </div>
    </Grid>
  );
};

export default ButtonBlock;
