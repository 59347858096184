import React from "react";

interface FilterPillProps {
  onClick: () => void;
  children: string | React.ReactNode;
  isActive: boolean;
  variant?: "primary" | "secondary";
}

const FilterPill: React.FC<FilterPillProps> = ({
  onClick,
  children,
  isActive,
  variant = "primary",
}) => {
  const classes = {
    primary: "bg-grey-light text-primary-black",
    secondary: "bg-white border border-secondary-aubergine text-primary-black",
  };
  return (
    <button
      onClick={onClick}
      data-gtm-id="faq_choice"
      className={`capitalize text-label-xs tablet:py-3.5 tablet:px-6 tablet:text-label-sm flex cursor-pointer select-none flex-row items-center justify-between rounded-[40px] px-4 py-3 font-sans font-bold text-nowrap ${
        isActive ? "bg-secondary-aubergine text-white" : classes[variant]
      }`}
    >
      {children}
    </button>
  );
};

export default FilterPill;
