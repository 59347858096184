"use client";

import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { UnControlledCheckbox } from "./UnControlledCheckbox";

export interface ControlledCheckboxProps {
  name: string;
  label?: string;
  className?: string;
  defaultChecked?: boolean;
  value?: string;
}

export const ControlledCheckbox: React.FC<ControlledCheckboxProps> = ({
  name,
  label,
  className,
  value,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value: checked, disabled } }) => (
        <UnControlledCheckbox
          name={name}
          label={label}
          disabled={disabled}
          className={className}
          value={value}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />
  );
};
