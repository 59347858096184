import clsx from "clsx";
import type { DetailedHTMLProps, FC, OptionHTMLAttributes, ReactNode } from "react";

type Props = DetailedHTMLProps<OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement> & {
  children: ReactNode;
  value: number | string;
};

export const Option: FC<Props> = ({ children, value, ...props }) => {
  return (
    <option
      value={value}
      {...props}
      className={clsx(
        "font-sans font-bold text-primary-black background-grey-light disabled:text-grey-dark",
        props.className
      )}
    >
      {children}
    </option>
  );
};
