import React from "react";
import useHoverState from "@/hooks/useHoverState";
import CardImage from "../Card/CardImage";
import BlogCardBody from "./BlogCardBody";

interface BlogCardContentProps {
  caption?: React.ReactNode;
  title?: React.ReactNode;
  label?: string;
  image?: string;
  buttonText?: string | null;
  darkThemeEnabled?: boolean;
  linkDisabled?: boolean;
}

const BlogCardContent: React.FC<BlogCardContentProps> = ({
  caption,
  title,
  label,
  image,
  buttonText,
  darkThemeEnabled,
  linkDisabled,
}) => {
  const [hoverState, setIsHovered] = useHoverState();
  const isHovered = linkDisabled ? false : hoverState;
  return (
    <div {...setIsHovered} className="relative flex flex-1 cursor-pointer flex-col">
      <CardImage imageUri={image} labelText={label} />
      <BlogCardBody
        caption={caption}
        title={title}
        buttonText={buttonText}
        isHovered={isHovered}
        darkThemeEnabled={darkThemeEnabled}
      />
    </div>
  );
};

export default BlogCardContent;
