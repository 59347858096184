import LicencePLate from "license-plate";

const toUpperCase = (value: string): string => {
  return value.toUpperCase();
};

const toLowerCase = (value: string): string => {
  return value.toLowerCase();
};

const toIban = (input: string): string => {
  const cleaned = input.replace(/\W/gi, "").toUpperCase();

  return cleaned.match(/.{1,4}/g)?.join(" ") ?? "";
};

const toThousandWithDot = (value: string): string => {
  const cleanValue = value.replaceAll(/\D/g, "");

  const euroFormatter = new Intl.NumberFormat("nl-NL", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return euroFormatter.format(Number(cleanValue));
};

const toCurrency = (value: string): string => {
  const parseValue = (value: string): number => {
    const cleanValue = value.replace(/\./g, "").replace(",", ".");
    return parseFloat(cleanValue);
  };

  const numberValue = parseValue(value);
  if (isNaN(numberValue)) {
    return value; // Return original value if parsing fails
  }
  // const cleanValue = value.replaceAll(/\D/g, "");
  const euroFormatter = new Intl.NumberFormat("nl-NL", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  });

  return euroFormatter.format(numberValue);
};

const toLicencePlate = (value: string): string => {
  const plate = new LicencePLate(value);
  if (!plate.valid()) {
    return value;
  }
  return plate.pretty();
};

const toMaxLengthValue = (value: string, maxLength: number): string => {
  return value.length > maxLength ? value.substring(0, maxLength) : value;
};

export const formatters = {
  toMaxLengthValue,
  toIban,
  toUpperCase,
  toLowerCase,
  toThousandWithDot,
  toCurrency,
  toLicencePlate,
};
