"use client";

import { type FC, FocusEventHandler, forwardRef } from "react";
import { themePreset } from "@hiltermann/tailwind";
import clsx from "clsx";
import { Checkmark } from "../../icons";

interface UnControlledCheckboxProps {
  value?: string;
  ref?: any;
  name: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  className?: string;
  label?: string;
  disabled?: boolean;
}

export const UnControlledCheckbox: FC<UnControlledCheckboxProps> = forwardRef<
  HTMLInputElement,
  UnControlledCheckboxProps
>(({ disabled, value, name, onBlur, onChange, className, label, checked }, ref) => {
  return (
    <label className="hover:cursor-pointer">
      <div className={clsx(className, "flex items-center")}>
        <div className="border-grey-asphalt relative size-4 rounded-sm border focus-within:border-secondary-burgundy">
          <input
            ref={ref}
            type="checkbox"
            className="absolute opacity-0"
            name={name}
            disabled={disabled}
            checked={checked}
            value={value}
            onBlur={onBlur}
            onChange={(e) => {
              onChange && onChange(e.target.checked);
            }}
            data-testid={`${name}-checkbox`}
          />
          {checked && (
            <Checkmark
              width={17}
              strokeWidth={2}
              // @ts-ignore TODO: check typing
              color={themePreset.theme?.colors?.secondary.burgundy}
              className="absolute right-[-4px] top-[-2px] flex items-center"
              data-testid={`${name}-checkmark`}
            />
          )}
        </div>
        <div className="text-p-sm ml-4 flex items-center font-serif">{label}</div>
      </div>
    </label>
  );
});
