import React from "react";
import { Button } from "@hiltermann/components/form";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Path } from "@/utilities/types";
import { FaqBlockProps } from "../FaqMiniBlock";
import { Accordion, AccordionItem } from "@hiltermann/components/ui";
import clsx from "clsx";

type ButtonTarget = Path<FaqBlockProps, ["faq_button_target", number]>;

interface FaqProps {
  title?: string | null;
  items: { title: string; content: React.ReactNode }[];
  buttonText?: string | null;
  buttonTarget?: ButtonTarget;
  className?: string;
}

const Faq: React.FC<FaqProps> = ({ title, items, buttonText, buttonTarget, className }) => {
  const { type, href, onClick, isValid } = useButtonTarget(buttonTarget);

  if (!items) return null;

  return (
    <div
      className={clsx(
        `tablet:col-span-7 tablet:p-10 desktop-s:py-20 desktop-s:px-32 col-span-full`,
        className
      )}
    >
      {title && <h4 className="text-h3-sm tablet:text-h4 font-bold tracking-wider">{title}</h4>}

      <Accordion className="tablet:mt-12 mt-8">
        {items.map((item, index) => (
          <AccordionItem
            title={<span className="text-left inline-block">{item.title || ""}</span>}
            key={index}
          >
            <span className="text-p-sm font-serif">{item.content}</span>
          </AccordionItem>
        ))}
      </Accordion>

      {buttonText && isValid ? (
        <Button
          type={type}
          href={href}
          onClick={onClick}
          variant="secondary"
          className="tablet:my-10 my-8"
        >
          {buttonText}
        </Button>
      ) : undefined}
    </div>
  );
};

export default Faq;
