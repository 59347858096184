"use client";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { UncontrolledRadioButton } from "./UncontrolledRadioButton";
import { ControlledProps } from "./types";

/**
 * A controlled radio button component that integrates with the `react-hook-form` library.
 *
 * @component
 * @example
 * ```tsx
 * <ControlledRadioButton
 *   name="gender"
 *   className="radio-button"
 *   value="male"
 *   data-testid="gender-radio"
 *   disabled={false}
 *   label="Male"
 * />
 * ```
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the radio button.
 * @param {RadioButtonVariant} props.variant - The variant of the radio button.
 * @param {string} props.className - The CSS class name for styling the radio button.
 * @param {string} props.value - The value of the radio button.
 * @param {string} props.data-testid - The data-testid attribute for testing purposes.
 * @param {boolean} props.disabled - Whether the radio button is disabled or not.
 * @param {string} props.label - The label text for the radio button.
 * @returns {JSX.Element} The rendered `ControlledRadioButton` component.
 */

export const ControlledRadioButton: FC<ControlledProps> = ({
  variant,
  name,
  className,
  value,
  ["data-testid"]: testId,
  disabled,
  label,
  onChange,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      render={({ field }) => (
        <UncontrolledRadioButton
          label={label}
          className={className}
          // defaultChecked={defaultChecked} // this is handled by setting a defaultvalue in the useForm hook
          name={name}
          variant={variant}
          value={value}
          onChange={(e) => {
            field.onChange(e);
            onChange && onChange(e);
          }}
          checked={field.value === value}
          data-testid={testId}
          disabled={field.disabled}
        />
      )}
    />
  );
};
