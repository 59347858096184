"use client";
import { type FC, memo, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextInputType } from "../shared/types";
import { UncontrolledInput } from "./UncontrolledInput";

export interface ControlledInputProps {
  name: string;
  description?: string;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  formatter?: (value: string, previousValue: string) => string;
  formatterOnBlur?: (value: string, previousValue: string) => string;
  label?: string;
  children?: ReactNode;
  onBlur?: (value: string) => void;
  optionalLabelText?: string;
  placeholder?: string;
  type?: TextInputType;
  error?: string;
  errorFormatter?: (value: string) => string;
  inputPrefix?: React.ReactNode;
  inputPostfix?: React.ReactNode;
  success?: ReactNode;
  readOnly?: boolean;
}

export const ControlledInput: FC<ControlledInputProps> = memo(
  ({
    className,
    inputClassName,
    labelClassName,
    children,
    description,
    disabled,
    onBlur,
    error,
    formatter,
    formatterOnBlur,
    label,
    name,
    optionalLabelText,
    placeholder,
    type,
    errorFormatter,
    inputPrefix,
    inputPostfix,
    success,
    readOnly = false,
  }) => {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        disabled={disabled}
        render={({ field, fieldState }) => {
          const errorMessage = error ?? fieldState.error?.message ?? "";
          const formattedError =
            errorFormatter && errorMessage !== "" ? errorFormatter(errorMessage) : errorMessage;

          return (
            <UncontrolledInput
              className={className}
              readOnly={readOnly}
              inputClassName={inputClassName}
              labelClassName={labelClassName}
              description={description}
              disabled={field.disabled}
              error={formattedError}
              formatter={formatter}
              formatterOnBlur={formatterOnBlur}
              inputRef={field.ref}
              label={label}
              name={field.name}
              onBlur={(value) => {
                field.onBlur();
                if (onBlur) {
                  onBlur(value);
                }
              }}
              onChange={field.onChange}
              optionalLabelText={optionalLabelText}
              placeholder={placeholder}
              type={type}
              value={field.value || ""}
              inputPrefix={inputPrefix}
              inputPostfix={inputPostfix}
              success={success}
            >
              {children}
            </UncontrolledInput>
          );
        }}
      />
    );
  }
);
