import React from "react";
import { Grid } from "@hiltermann/components/ui";

interface ListProps<T> {
  data: T[];
  renderItem: (item: T) => React.ReactNode;
  className?: string | ((index: number) => string);
}

const List = <T,>({ data, renderItem, className }: ListProps<T>) => {
  return (
    <Grid>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className={className && typeof className === "function" ? className(index) : className}
          >
            {renderItem(item)}
          </div>
        );
      })}
    </Grid>
  );
};

export default List;
