import Image from "next/legacy/image";
import { Grid } from "@hiltermann/components/ui";
import { parseText, parseUnderline } from "@/utilities/htmlParser";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";

export type PageHeaderThreeProps = GetDynamicContentItemProps<"PageHeaderThree">;

const PageHeaderThree: React.FC<PageHeaderThreeProps> = ({
  title,
  subtitle,
  body,
  image: img,
  tagline,
  className,
}) => {
  const image = img?.[0];
  return (
    <header data-block="PageHeaderThree" className={`bg-grey-light ${className ?? ""}`}>
      <Grid className="relative z-0 h-full" containerClass="mr-0">
        <div className="tablet:mt-[82px] tablet:mb-32 relative col-span-6 my-12">
          {title && (
            <p className="text-p text-secondary-burgundy tablet:mb-10 tablet:text-h3-sm mb-2 line-clamp-1 font-serif tracking-wider">
              {title}
            </p>
          )}
          {subtitle && (
            <h1 className="text-h1-sm tablet:mb-10 tablet:text-h1-md line-clamp-2 font-sans font-bold tracking-wider">
              {subtitle}
            </h1>
          )}
          {body && (
            <div className="text-p tablet:mb-10 tablet:line-clamp-4 font-serif tracking-wider">
              {parseText(body)}
            </div>
          )}
          {tagline && (
            <h4 className="text-h5-sm tablet:mt-12 tablet:text-h4-sm mt-6 font-sans font-bold tracking-wider">
              {parseUnderline(tagline)}
            </h4>
          )}
        </div>
        {image?.desktop && (
          <div className="gradient-2 tablet:block relative -z-10 col-span-12 col-start-10 mr-[-calc(120vh/2)] hidden">
            <div className="absolute bottom-0 left-0 -mr-24 aspect-[125/200] h-[90%] max-h-[1080px] -translate-x-1/2">
              <Image
                src={image.desktop}
                alt={image.description || "header image"}
                layout="fill"
                objectFit="cover"
                priority={true}
              />
            </div>
          </div>
        )}
      </Grid>
      {image?.mobile && (
        <div className="tablet:hidden relative -mt-10 block aspect-[360/220] h-full min-h-56 pt-[32%]">
          <div className="gradient-2 h-full pl-5">
            <div className="absolute bottom-0 left-20 size-full">
              <Image
                src={image.mobile}
                alt={image.description || "header image"}
                layout="fill"
                objectFit="contain"
                priority={true}
              />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default PageHeaderThree;
