import type { FC } from "react";
import clsx from "clsx";

export interface ErrorProps {
  error: string | null;
  className?: string;
  "data-testid"?: string;
}

export const UnControlledError: FC<ErrorProps> = ({ error, "data-testid": testId, className }) => {
  if (!error) return null;

  return (
    <div
      className={clsx(
        "text-forms-sm text-secondary-aubergine tablet:text-forms-md p-2 font-semibold",
        className
      )}
      data-testid={testId}
    >
      {error}
    </div>
  );
};
