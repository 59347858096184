import React from "react";
import Image from "next/legacy/image";
import Trengo from "@/services/trengo/Trengo";
import TrengoWidget from "@/services/trengo/TrengoWidget";
import { Grid } from "@hiltermann/components/ui";
import { useDialogForms } from "@/components/Layout/useDialogForms";
import { Button } from "@hiltermann/components/form";
import { GetContactPageQuery } from "@/services/prepr/queries/getContactPage.generated";
import { Path } from "@/utilities/types";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Accordion, AccordionItem } from "@hiltermann/components/ui";
import clsx from "clsx";

const clickChat = () => {
  Trengo.renderWidget();
  Trengo.openWidget("chat");
};
const whatsappHandler = () => {
  window.open("https://api.whatsapp.com/send?phone=31885543915", "_blank");
};

type Props = Path<
  GetContactPageQuery,
  ["ContactPages", "items", number, "contact_service_block"]
> & {
  className?: string;
};

const ContactServiceBlock: React.FC<Props> = ({
  className,
  title,
  chat_title,
  chat_text,
  chat_button_text,
  whatsapp_title,
  whatsapp_text,
  whatsapp_button_text,
  email_title,
  email_text,
  email_button_text,
  email_button2_text,
  email_button2_target,
  phone_title,
  phone_text,
  phone_button_text,
  phone_number,
  phone_callmeback_button_text,
  employee_name,
  employee_role,
  employee_image,
}) => {
  const clickWhatsapp = () => whatsappHandler();
  const { openDialog } = useDialogForms();
  const email_button2_props = useButtonTarget(email_button2_target?.[0]);

  const contactItems = [
    {
      title: chat_title,
      text: chat_text,
      icon: "/icons/contact-chat.svg",
      buttons: (
        <Button onClick={clickChat} variant="tertiary" size="small" className="self-start">
          {chat_button_text || "Start chat"}
        </Button>
      ),
    },
    {
      title: email_title,
      text: email_text,
      icon: "/icons/contact-email.svg",
      buttons: (
        <>
          <Button
            onClick={openDialog("case-contact", "")}
            variant="tertiary"
            size="small"
            className="self-start"
          >
            {email_button_text || "Naar het contactformulier"}
          </Button>
          {email_button2_props.isValid && email_button2_text && (
            <Button
              type={email_button2_props.type}
              href={email_button2_props.href}
              onClick={email_button2_props.onClick}
              variant="tertiary"
              size="small"
              className="self-start"
            >
              {email_button2_text}
            </Button>
          )}
        </>
      ),
    },
    {
      title: phone_title,
      text: phone_text,
      icon: "/icons/contact-telephone.svg",
      buttons: (
        <>
          <Button
            variant="tertiary"
            size="small"
            type="link"
            href={`tel:${phone_number}`}
            icon={
              <Image
                src="/icons/contact-telephone.svg"
                width="24"
                height="24"
                alt="telephone icon"
              />
            }
            iconPosition="start"
          >
            {phone_button_text || "088-5543900"}
          </Button>
          <Button onClick={openDialog("case-callMeBack", "")} variant="tertiary" size="small">
            {phone_callmeback_button_text || "Bel mij terug"}
          </Button>
        </>
      ),
    },
  ];

  const accordionItemData = contactItems.map(({ title, text, buttons, icon }) => ({
    title: (
      <div className="flex text-white">
        <Image src={icon} alt={`${title} icon`} width="24" height="24"></Image>
        <span className="ml-4">{title}</span>
      </div>
    ),
    content: (
      <>
        <p className="whitespace-pre-wrap">{text}</p>
        <div className="tablet:flex-row tablet:gap-8 mt-6 flex flex-1 flex-col gap-4">
          {buttons}
        </div>
      </>
    ),
  }));

  return (
    <div id="contact" className={clsx("gradient-2 text-white", className)}>
      <TrengoWidget />
      <Grid>
        <div className="tablet:col-span-8 tablet:py-0 desktop-s:col-span-7 col-span-full py-14">
          <h2 className="text-h2-sm tablet:mt-[136px] tablet:text-h4 font-bold tracking-wider">
            {title}
          </h2>
          <Accordion className="tablet:mb-[152px] tablet:mr-20 mt-10">
            {accordionItemData.map((item, index) => (
              <AccordionItem title={item.title || ""} key={index}>
                <span className="text-p font-serif">{item.content}</span>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="desktop-s:col-span-5 desktop-s:col-start-8 desktop-s:block relative hidden">
          <div className="absolute left-[15%] top-full z-10 h-[364px] w-40 -translate-x-1/2 -translate-y-full">
            <p className="text-forms mt-2 font-sans font-bold tracking-wider text-white">
              {employee_name}
            </p>
            <p className="text-p mt-2 font-serif tracking-wider text-white">{employee_role}</p>
          </div>
          <div className="absolute left-1/2 top-full h-[456px] w-[344px] -translate-x-1/2 -translate-y-full">
            <div className="relative size-full">
              {employee_image?.[0]?.default && (
                <Image
                  src={employee_image?.[0]?.default}
                  alt={employee_image?.[0]?.description || "employee"}
                  layout="fill"
                  objectFit="cover"
                />
              )}
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default ContactServiceBlock;
