import Image from "next/legacy/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import Logo from "@/public/icons/logo.svg";
import User from "@/public/icons/user.svg";
import Search from "@/public/icons/search.svg";
import Close from "@/public/icons/close.svg";
import { Feature } from "@/components/shared/Feature";
import { NavbarProps } from "@/components/Layout/Navbar";
import SearchInput from "@/components/Layout/Navbar/SearchInput";
import NavbarSecondaryLink from "./NavbarDesktop/NavbarSecondaryLink";
import NavbarMainLink from "./NavbarDesktop/NavbarMainLink";
import useStateAndDelayNull from "./NavbarDesktop/useStateAndDelayNull";
import AccountMenu from "./NavbarDesktop/AccountMenu";
import Menu from "./NavbarDesktop/Menu";
import { extractMenuItems } from "./extractMenuItems";

export type HoverTarget =
  | null
  | "nothing"
  | "autolease"
  | "equipment-lease"
  | "voorraadfinanciering"
  | "fiets-home"
  | "fiets-aanbod"
  | "calculator"
  | "vind-dealer"
  | "account"
  | "over-ons"
  | "service";
const defaultHoverTaget: HoverTarget = null;

const NavbarDesktop: React.FC<NavbarProps> = ({ navigation, type = "default" }) => {
  const [hoverTarget, setHoverTarget, resetDelay] = useStateAndDelayNull<HoverTarget>(
    defaultHoverTaget,
    300
  );
  const [searchVisible, setSearchVisible] = useState(false);
  const [positionClass, setPositionClass] = useState("translate-y-0");
  const hover = (target: HoverTarget) => () => setHoverTarget(target);
  const {
    menuFietsHome,
    menuFietsAanbod,
    menuFietsHoeWerktHet,
    menuFietsVindDealer,
    menuAutoLease,
    menuEquipmentLease,
    menuVoorraadFinanciering,
    menuAboutUs,
    menuService,
  } = extractMenuItems(navigation);

  const hoverAbout = menuAboutUs?.hasMenu ? hover("over-ons") : hover("nothing"); // fallback functionality
  const hoverService = menuService?.hasMenu ? hover("service") : hover("nothing"); // fallback functionality
  const searchInputRef = useRef<HTMLInputElement>(null);
  const toggleSearchVisible = () => {
    setSearchVisible((current) => !current);
  };
  useEffect(() => {
    if (searchVisible) searchInputRef.current?.focus();
  }, [searchVisible]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      let lastScrollTop = window.scrollY;
      let initialScrollUp = false;
      let initialScrollDown = true;
      const scrollListener = function () {
        const scrollTop = window.scrollY;
        if (scrollTop < lastScrollTop) {
          // Scroll up
          if (initialScrollUp) {
            setPositionClass("translate-y-0");
          }
          initialScrollUp = false;
          initialScrollDown = true;
        } else if (scrollTop > lastScrollTop) {
          // Scroll down
          if (initialScrollDown) {
            setPositionClass("-translate-y-[84px]");
          }
          initialScrollDown = false;
          initialScrollUp = true;
        }
        lastScrollTop = scrollTop;
      };
      window.addEventListener("scroll", scrollListener);
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    }
  }, []);

  const router = useRouter();
  useEffect(() => {
    setSearchVisible(router.route === "/search");
  }, [router.route]);

  return (
    <>
      <nav
        className={`tablet:block fixed z-40 hidden w-full text-white transition-transform ${positionClass}`}
        onMouseEnter={resetDelay}
        onMouseLeave={hover(defaultHoverTaget)}
      >
        <Menu nodes={menuAutoLease?.menuItems || []} show={hoverTarget === "autolease"} />
        <Menu
          nodes={menuEquipmentLease?.menuItems || []}
          show={hoverTarget === "equipment-lease"}
        />
        <Menu
          nodes={menuVoorraadFinanciering?.menuItems || []}
          show={hoverTarget === "voorraadfinanciering"}
        />
        {menuAboutUs?.hasMenu && (
          <Menu nodes={menuAboutUs?.menuItems} show={hoverTarget === "over-ons"} />
        )}
        {menuService?.hasMenu && (
          <Menu nodes={menuService?.menuItems} show={hoverTarget === "service"} />
        )}
        <AccountMenu show={hoverTarget === "account"} />
        <Menu
          nodes={menuFietsAanbod?.menuItems || []}
          show={hoverTarget === "fiets-aanbod"}
          adviesGesprekButton={false}
        />
        <Menu
          nodes={menuFietsHoeWerktHet?.menuItems || []}
          show={hoverTarget === "calculator"}
          adviesGesprekButton={false}
        />
        <Menu
          nodes={menuFietsVindDealer?.menuItems || []}
          show={hoverTarget === "vind-dealer"}
          adviesGesprekButton={false}
        />

        <div className="bg-primary-black relative flex grow justify-center">
          <div className="max-w-screen-desktop-xl flex w-full px-8">
            <div className="desktop-s:w-40 flex w-48 flex-initial">
              <Link
                href="/"
                className="py-3"
                onMouseEnter={hover("nothing")}
                data-gtm-id="logo_navigation"
              >
                <Image src={Logo} alt="Hiltermann Lease icon" width="56" height="56"></Image>
              </Link>
            </div>

            <div className="flex flex-initial">
              {type === "fiets" ? (
                <>
                  <NavbarMainLink
                    href={menuFietsHome?.target ?? "/fiets/werknemer"}
                    title="Home"
                    onMouseEnter={hover("fiets-home")}
                    isHovering={hoverTarget === "fiets-home"}
                  />
                  <NavbarMainLink
                    href={menuFietsAanbod?.target ?? "/fiets/aanbod"}
                    title="Fiets aanbod"
                    onMouseEnter={hover("fiets-aanbod")}
                    isHovering={hoverTarget === "fiets-aanbod"}
                  />
                  <NavbarMainLink
                    href={menuFietsHoeWerktHet?.target ?? "/fiets/calculator"}
                    title="Calculator"
                    onMouseEnter={hover("calculator")}
                    isHovering={hoverTarget === "calculator"}
                  />
                  <NavbarMainLink
                    href={menuFietsVindDealer?.target ?? "/fiets/vind-dealer"}
                    title="Vind een dealer"
                    onMouseEnter={hover("vind-dealer")}
                    isHovering={hoverTarget === "vind-dealer"}
                  />
                </>
              ) : (
                <>
                  <NavbarMainLink
                    href={menuAutoLease?.target ?? "/autolease"}
                    title="Autolease"
                    onMouseEnter={hover("autolease")}
                    isHovering={hoverTarget === "autolease"}
                  />
                  <NavbarMainLink
                    href={menuEquipmentLease?.target ?? "/equipment-lease"}
                    title="Equipment lease"
                    onMouseEnter={hover("equipment-lease")}
                    isHovering={hoverTarget === "equipment-lease"}
                  />
                  <NavbarMainLink
                    href={menuVoorraadFinanciering?.target ?? "/voorraadfinanciering"}
                    title="Voorraad financiering"
                    onMouseEnter={hover("voorraadfinanciering")}
                    isHovering={hoverTarget === "voorraadfinanciering"}
                  />
                </>
              )}
            </div>

            <div
              className={`${
                searchVisible
                  ? "ml-auto flex grow transition-all ease-out"
                  : "ml-auto flex flex-initial transition-all ease-in"
              }`}
            >
              <div className={`${searchVisible ? "hidden" : "ml-auto flex flex-initial"}`}>
                {type === "default" && (
                  <NavbarSecondaryLink
                    href={menuAboutUs?.target ?? "/over-ons"}
                    onMouseEnter={hoverAbout}
                    title="Over ons"
                  />
                )}
                <NavbarSecondaryLink
                  href={menuService?.target ?? "/service-contact"}
                  onMouseEnter={hoverService}
                  title="Service & Contact"
                />
              </div>
              <SearchInput
                inputRef={searchInputRef}
                className={`${
                  searchVisible
                    ? "ml-auto flex !max-w-full flex-initial grow place-self-center pl-10"
                    : "hidden"
                }`}
              />
            </div>

            <div
              className={`${
                searchVisible
                  ? "ml-auto flex flex-initial items-center "
                  : "ml-auto flex flex-initial items-center "
              }`}
            >
              <button
                type="button"
                className="border-secondary-burgundy mx-4 flex size-16 items-center justify-center rounded-2xl hover:border-2"
                onClick={() => toggleSearchVisible()}
                data-gtm-id="search_icon"
                name="Search icon"
              >
                <Image
                  src={searchVisible ? Close : Search}
                  alt="Search icon"
                  width="32"
                  height="32"
                />
              </button>
              <Feature
                require="enable_login"
                fallback={
                  <div
                    className={`border-secondary-burgundy flex size-16 items-center justify-center rounded-2xl border-2 ${
                      hoverTarget === "account" ? "border-secondary-burgundy" : "border-none"
                    }`}
                    onMouseEnter={hover("account")}
                    data-gtm-id="login_icon"
                  >
                    <Image src={User} alt="User icon" width="40" height="40" />
                  </div>
                }
              >
                <Link
                  href="/"
                  className={`border-secondary-burgundy flex size-16 items-center justify-center rounded-2xl border-2 ${
                    hoverTarget === "account" ? "border-secondary-burgundy" : "border-none"
                  }`}
                  onMouseEnter={hover("account")}
                  data-gtm-id="login_icon"
                >
                  <Image src={User} alt="User icon" width="40" height="40" />
                </Link>
              </Feature>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarDesktop;
