import { type FC } from "react";
import clsx from "clsx";

export interface LabelProps {
  label: string;
  description?: string;
  optionalLabelText?: string;
  className?: string;
  id: string;
}

export const Label: FC<LabelProps> = ({ label, optionalLabelText, description, className, id }) => {
  return (
    <>
      <div className={clsx("text-forms-md flex", !label && "sr-only")}>
        <label htmlFor={id} className={clsx("mb-2 font-sans font-semibold leading-6", className)}>
          {label}
        </label>
        {optionalLabelText && (
          <div className="text-grey-dark ml-2 font-sans">({optionalLabelText})</div>
        )}
      </div>
      {description && <p className="text-forms-md text-primary-black mb-2">{description}</p>}
    </>
  );
};
