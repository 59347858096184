import React from "react";
import { Grid } from "@hiltermann/components/ui";
import Carousel from "./CardListOrCarousel/Carousel";
import List from "./CardListOrCarousel/List";

interface CardListOrCarouselProps<T> {
  data: T[];
  renderItem: (item: T) => React.ReactNode;
  listClass?: string | ((index: number) => string);
}

export const CardListOrCarousel = <T,>({
  data,
  renderItem,
  listClass,
}: CardListOrCarouselProps<T>) => {
  return (
    <>
      <div className="tablet:block hidden">
        <List<T> data={data} renderItem={renderItem} className={listClass} />
      </div>
      <div className="tablet:hidden block">
        {data.length === 1 ? (
          <Grid>
            <div className="col-span-full">{renderItem(data[0])}</div>
          </Grid>
        ) : (
          <Carousel<T> data={data} renderItem={renderItem} />
        )}
      </div>
    </>
  );
};
