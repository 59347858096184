import clsx from "clsx";
import type { FC } from "react";

/**
 * Component for rendering children in a side-by-side layout.
 *
 * @component
 * @example
 * ```tsx
 * import { SideBySide } from "./SideBySide";
 *
 * const MyComponent = () => {
 *   return (
 *     <SideBySide className="my-container">
 *       <div>Child 1</div>
 *       <div>Child 2</div>
 *     </SideBySide>
 *   );
 * };
 * ```
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name to apply to the container.
 * @param {React.ReactNode} props.children - The children to render in a side-by-side layout.
 * @returns {JSX.Element} The rendered `SideBySide` component.
 */

export interface SideBySideProps {
  children: React.ReactNode[];
  className?: string;
}

export const SideBySide: FC<SideBySideProps> = ({ children, className }) => (
  <div className={clsx("tablet:grid-cols-2 grid gap-6", className)}>{children}</div>
);
