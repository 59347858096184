"use client";
import { type FC } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { UnControlledError } from "./UnControlledError";

interface ControlledErrorProps {
  name: string;
  t: (key: string) => string;
  className?: string;
  "data-testid"?: string;
}

export const ControlledError: FC<ControlledErrorProps> = ({
  name,
  t,
  className,
  "data-testid": testId,
}) => {
  const { control } = useFormContext<FieldValues>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState }) => {
        if (!fieldState.error) {
          return <></>;
        }

        const errorMessage = fieldState.error?.message ? t(fieldState.error.message) : "";

        return (
          <UnControlledError
            className={className}
            data-testid={testId || name + "-error"}
            error={errorMessage}
          />
        );
      }}
    />
  );
};
