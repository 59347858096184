import clsx from "clsx";
import Link from "next/link";
import { Props } from "./types";
import { sizeStyles, styles, variantHoverStyles } from "./styles";
import { twMerge } from "tailwind-merge";

interface ButtonContentProps {
  icon?: React.ReactElement;
  iconPosition: "start" | "end" | "center";
  children: React.ReactNode;
}

const ButtonContent = ({ icon, iconPosition, children }: ButtonContentProps) => (
  <>
    {icon && iconPosition === "start" && <span className="mr-2 flex shrink-0">{icon}</span>}
    <span className="line-clamp-2">{children}</span>
    {icon && iconPosition === "center" && <span className="flex shrink-0">{icon}</span>}
    {icon && iconPosition === "end" && <span className="ml-2 flex shrink-0">{icon}</span>}
  </>
);

export const Button: React.FC<Props> = ({
  type = "button",
  variant = "primary",
  className,
  children,
  disabled = false,
  fullWidth = false,
  icon,
  href = "#",
  onClick,
  alignLeft,
  "data-testid": testId,
  "aria-label": ariaLabel,
  iconPosition = "end",
  size = "normal",
}) => {
  const classes = twMerge(
    `border-gradient-base transition-1 font-sans
    shadow-button relative inline-flex flex-row items-center
    font-semibold outline-2
    before:bg-gradient-to-r focus-visible:outline
		disabled:opacity-50`,
    sizeStyles[size],
    fullWidth && "w-full",
    alignLeft ? "justify-start" : "justify-center",
    styles[variant],
    !disabled && variantHoverStyles[variant],
    className && className.trim()
  );

  if (type === "button") {
    return (
      <button
        data-gtm-id={`btn-${variant}`}
        data-testid={testId}
        type="button"
        className={classes}
        onClick={onClick}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        <ButtonContent icon={icon} iconPosition={iconPosition}>
          {children}
        </ButtonContent>
      </button>
    );
  }

  if (type === "link") {
    return (
      <Link href={href || "404"} data-testid={testId} data-gtm-id={`btn-${variant}`}>
        <span className={clsx("line-clamp-2", classes)} aria-label={ariaLabel}>
          <ButtonContent icon={icon} iconPosition={iconPosition}>
            {children}
          </ButtonContent>
        </span>
      </Link>
    );
  }

  if (type === "externalLink") {
    return (
      <a
        className={classes}
        href={href.startsWith("http") ? href : `//${href}`}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={testId}
        data-gtm-id={`btn-${variant}`}
      >
        <ButtonContent icon={icon} iconPosition={iconPosition}>
          {children}
        </ButtonContent>
      </a>
    );
  }
  // this variant is used in hiltermannlease.nl
  if (type === "div") {
    return (
      <div className={classes} data-testid={testId} data-gtm-id={`btn-${variant}`} role="button">
        <ButtonContent icon={icon} iconPosition={iconPosition}>
          {children}
        </ButtonContent>
      </div>
    );
  }

  return <></>;
};
