import React from "react";
import Image from "next/legacy/image";
import { Grid } from "@hiltermann/components/ui";
import TextLink from "@/components/shared/TextLink";
import { Button } from "@hiltermann/components/form";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";

type PreprModel = GetDynamicContentItemProps<"ContactPersonBlock">;
export type ContactPersonBlockProps = PreprModel & {
  className?: string | undefined;
};

const ContactPersonBlock: React.FC<ContactPersonBlockProps> = ({
  heading,
  eyebrow_line,
  description,
  primary_button_text,
  primary_button_target,
  secondary_button_text,
  secondary_button_target,
  person_name,
  person_title,
  person_image,
  className,
}) => {
  const button1 = useButtonTarget(primary_button_target?.[0]);
  const button2 = useButtonTarget(secondary_button_target?.[0]);
  const imageUrl = person_image?.[0]?.default || person_image?.[0]?.url;
  return (
    <section data-block="ContactPersonBlock" className={`relative ${className ?? ""}`}>
      <Grid className="gradient-2 relative overflow-hidden pt-14">
        <div className="tablet:block relative col-span-5 col-start-2 row-start-1 hidden h-full">
          <div className="relative z-10 flex h-full w-40 flex-col justify-center">
            <p className="text-forms mt-2 font-sans font-bold text-white">{person_name}</p>
            <p className="text-p font-serif tracking-wider text-white">{person_title}</p>
          </div>
          <div className="absolute left-1/2 top-full h-[456px] w-[344px] -translate-x-1/2 -translate-y-full">
            <div className="relative size-full">
              {imageUrl && (
                <Image
                  src={imageUrl}
                  alt={person_image?.[0]?.description || person_name || ""}
                  layout="fill"
                  objectFit="cover"
                  unoptimized
                />
              )}
            </div>
          </div>
        </div>

        <div className="tablet:col-span-5 tablet:col-start-7 tablet:py-24 col-span-full pb-16">
          <p className="text-p text-primary-mint tablet:text-intro font-serif tracking-wider">
            {eyebrow_line}
          </p>
          <h2 className="text-h2-sm tablet:mt-5 tablet:text-h4 mt-4 font-sans font-bold tracking-wider text-white">
            {heading}
          </h2>
          <div className="text-p tablet:mt-10 mt-8 font-serif tracking-wider text-white">
            {description}
          </div>
          <div className="desktop-s:mt-10 desktop-s:flex-row desktop-s:items-center mt-6 flex flex-col items-start">
            {button1.isValid && primary_button_text && (
              <Button
                variant="tertiary"
                type={button1.type}
                href={button1.href}
                onClick={button1.onClick}
              >
                {primary_button_text}
              </Button>
            )}
            {button2.isValid && secondary_button_text && (
              <TextLink
                type={button2.type}
                href={button2.href}
                onClick={button2.onClick}
                className="desktop-s:ml-12 mt-4"
                variant="dark"
              >
                {secondary_button_text}
              </TextLink>
            )}
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default ContactPersonBlock;
