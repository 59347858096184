import React, { useState } from "react";
import { Grid } from "@hiltermann/components/ui";
import { GetDynamicContentItemProps } from "../DynamicContentBlock/types";
import { CookieNotification } from "../CookieNotification/CookieNotification";

export type OpenCookieNotificationLinkProps =
  GetDynamicContentItemProps<"OpenCookieNotificationLink">;

const OpenCookieNotificationLink: React.FC<OpenCookieNotificationLinkProps> = ({ className }) => {
  // NOTE: when adding new fields, make sure to add the component to the DynamicPage query
  const [openEditMode, setOpenEditMode] = useState(false);
  return (
    <Grid dataBlock="ParagraphBlock" className={`relative ${className ?? ""}`}>
      <div
        className="tablet:col-span-8 tablet:col-start-3 col-span-full text-black hover:cursor-pointer"
        onClick={() => setOpenEditMode((prev) => !prev)}
      >
        <div className="text-p-sm text-secondary-aubergine tablet:text-p font-serif tracking-wider underline">
          Cookie instellingen aanpassen
        </div>
      </div>
      <CookieNotification editMode={openEditMode} setEditMode={setOpenEditMode} />
    </Grid>
  );
};

export default OpenCookieNotificationLink;
