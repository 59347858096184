import React from "react";
import { Button } from "@hiltermann/components/form";
import TextLink from "@/components/shared/TextLink";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { Path } from "@/utilities/types";
import { PageHeaderTwoProps } from "../PageHeaderTwo";

type ButtonTarget = Path<PageHeaderTwoProps, ["button_target", number]>;
type TextLinkTarget = Path<PageHeaderTwoProps, ["textlink_target", number]>;

interface PageHeaderTwoLinksProps {
  buttonText?: string | null;
  buttonTarget?: ButtonTarget;
  textlinkText?: string | null;
  textlinkTarget?: TextLinkTarget;
}

const PageHeaderTwoLinks: React.FC<PageHeaderTwoLinksProps> = ({
  buttonText,
  buttonTarget,
  textlinkText,
  textlinkTarget,
}) => {
  const button = useButtonTarget(buttonTarget);
  const textlink = useButtonTarget(textlinkTarget);
  const buttonExists = buttonText && button.isValid;
  const textlinkExists = textlinkText && textlink.isValid;
  if (!buttonExists && !textlinkExists) return null;
  return (
    <div className="flex-0 desktop-s:mt-10 desktop-s:flex-row desktop-s:gap-10 mt-8 flex w-auto flex-col gap-6">
      {buttonExists && (
        <Button
          type={button.type}
          href={button.href}
          onClick={button.onClick}
          variant="secondary"
          className="desktop-s:self-center self-start"
        >
          {buttonText}
        </Button>
      )}
      {textlinkExists && (
        <TextLink
          type={textlink.type}
          href={textlink.href}
          onClick={textlink.onClick}
          variant="secondary"
          className={`desktop-s:self-center self-start ${!!button ? "desktop-s:mt-4" : "mt-0"}`}
        >
          {textlinkText}
        </TextLink>
      )}
    </div>
  );
};

export default PageHeaderTwoLinks;
