import React, { RefObject, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { UncontrolledInput } from "@hiltermann/components/form";

type SearchInputProps = {
  className: string;
  onAfterSubmit?: () => void;
  currentValueCallback?: (value: string | undefined) => void;
  inputRef?: RefObject<HTMLInputElement>;
};
const SearchInput = ({
  className,
  onAfterSubmit,
  currentValueCallback,
  inputRef,
}: SearchInputProps) => {
  const router = useRouter();
  const [currentValue, setCurrentValue] = useState<string>();

  useEffect(() => {
    const value = typeof router?.query?.q === "string" ? router.query.q : undefined;
    setCurrentValue(value);
  }, [router?.query?.q]);

  useEffect(() => {
    currentValueCallback && currentValueCallback(currentValue);
    const enterListener = (event: globalThis.KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        onEnter();
      }
    };
    document.addEventListener("keydown", enterListener);
    return () => {
      document.removeEventListener("keydown", enterListener);
    };
  }, [currentValue]);

  const onEnter = () => {
    if (currentValue !== "" && currentValue) router.push("/search?q=" + currentValue);
    onAfterSubmit && onAfterSubmit();
  };

  return (
    <UncontrolledInput
      name="search"
      inputRef={inputRef}
      placeholder="Zoeken"
      value={currentValue || ""}
      onChange={setCurrentValue}
      className={className}
      inputClassName="focus:outline-none"
    />
  );
};

export default SearchInput;
