import { ButtonVariant, Size } from "./types";

export const styles: Record<ButtonVariant, string> = {
  // mint
  primary: `
    text-primary-black bg-primary-mint from-white to-primary-mint shadow-[rgba(1,135,134,0.4)] before:p-[2px] outline-white
    focus:shadow-none focus:bg-primary-black focus:text-white focus:from-primary-mint focus:to-primary-mint
    active:shadow-none active:bg-primary-mint active:shadow-none active:text-primary-black
    `,
  // black
  secondary: `
    text-primary-black bg-white border-2 border-primary-black shadow-[rgba(144,64,85,0.82)] outline-primary-mint
    focus:shadow-none
    active:shadow-none active:border-secondary-burgundy active:text-secondary-burgundy
  `,
  //white
  tertiary: `
    text-white bg-primary-black from-secondary-burgundy to-transparent shadow[rgba(144,64,85,0.82)] before:p-[2px] outline-white
    focus:shadow-none focus:from-primary-black focus:to-primary-black
    active:shadow-none active:from-secondary-burgundy active:to-secondary-burgundy
  `,
  whiteBlack: `
    text-black bg-white shadow-[rgba(144,64,85,0.82)] before:p-[2px] outline-primary-secondary-burgundy
    focus:shadow-none focus:bg-primary-black focus:text-white
    active:shadow-none active:bg-gray-200 active:text-black
  `,
};

export const variantHoverStyles: Record<ButtonVariant, string> = {
  primary: `
    hover:bg-primary-black hover:text-white hover:from-primary-mint hover:to-transparent
    `,
  secondary: `
    hover:border-secondary-burgundy hover:text-secondary-burgundy
  `,
  tertiary: `
    hover:shadow[rgba(144,64,85,0.82)] hover:from-secondary-burgundy hover:to-secondary-burgundy
  `,
  whiteBlack: `
    hover:bg-primary-black hover:text-white hover:from-secondary-burgundy hover:to-secondary-burgundy
  `,
};

export const sizeStyles: Record<Size, string> = {
  normal: "py-4 px-8 text-button-sm tablet:text-button tablet:py-7 rounded-2xl before:rounded-2xl",
  small: "py-4 px-6 text-button-sm rounded-xl before:rounded-xl",
};
