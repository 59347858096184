import Image from "next/legacy/image";
import { parseText } from "@/utilities/htmlParser";
import { Grid } from "@hiltermann/components/ui";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";

export type StepByStepBlockProps = GetDynamicContentItemProps<"StepByStepBlock">;

const StepByStepBlock: React.FC<StepByStepBlockProps> = ({ title, image, steps }) => {
  return (
    <Grid className="tablet:my-10 tablet:min-h-[600px] my-6">
      <div className="tablet:col-span-6 tablet:grid-cols-6 tablet:grid-rows-6 col-span-4 grid grid-rows-4 flex-col">
        {image?.[0]?.default && (
          <div className="tablet:order-last tablet:col-span-5 tablet:row-span-5 relative row-span-2 h-full">
            <Image
              alt={image?.[0]?.description ?? "Stappenplan afbeelding"}
              src={image?.[0]?.default ?? "/none"}
              onError={() => null}
              layout="fill"
              className="tablet:object-cover object-contain"
            />
          </div>
        )}
        <div className="text-h2-sm tablet:order-first tablet:col-span-5 tablet:row-span-2 tablet:max-h-[9.5rem] row-span-2 mb-6 h-full flex-col overflow-hidden font-bold">
          {title && parseText(title)}
        </div>
      </div>
      <div className="bg-grey-light tablet:col-span-6 tablet:p-12 col-span-4 p-2 py-6">
        {steps &&
          steps.slice(0, 5).map((step) => {
            return (
              <div
                data-testid="step-by-step-step"
                className={"m-4 mb-6 flex"}
                key={`${step.number}${step.title}`}
              >
                <p className="text-h4-sm text-grey-asphalt tablet:pr-10 pr-4 font-bold">
                  {"0" + step.number}
                </p>
                <div className="">
                  <p className="text-h4-sm font-bold">{step.title}</p>
                  <div className="tablet:max-h-[4.5rem] overflow-hidden pt-2 font-serif leading-8">
                    {step.description && parseText(step.description)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Grid>
  );
};

export default StepByStepBlock;
