import { forwardRef } from "react";
import type { ChangeEvent, InputHTMLAttributes } from "react";

import clsx from "clsx";
import { ControlledProps } from "./types";

type UncontrolledProps = {
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  checked?: boolean;
  defaultChecked?: boolean;
};

export type RadioButtonProps = ControlledProps &
  UncontrolledProps &
  InputHTMLAttributes<HTMLInputElement>;

export const UncontrolledRadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      name,
      value,
      defaultChecked,
      label,
      onChange,
      onBlur,
      className,
      checked,
      disabled,
      variant = "classic",
      ["data-testid"]: testId,
      ...rest
    },
    ref
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(e);
    };

    const buttonVariant = clsx(
      `border-grey-asphalt has-[:checked]:shadow-secondary-burgundy
       flex rounded-lg border px-4 py-3.5 hover:shadow-[0_0_0_2px]
       has-[:checked]:border-transparent has-[:checked]:shadow-[0_0_0_2px]`
    );

    return (
      <label
        className={clsx(
          `accent-primary-black color-primary-black
          hover:shadow-grey-asphalt
          cursor-pointer items-center
          font-sans text-lg font-normal`,
          variant === "button" ? buttonVariant : "",
          className
        )}
      >
        <input
          className="checked:accent-secondary-burgundy mr-2"
          type="radio"
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          onBlur={handleBlur}
          checked={checked}
          disabled={disabled}
          data-testid={testId}
          ref={ref}
          {...rest}
        />
        {label}
      </label>
    );
  }
);
