import { Grid } from "@hiltermann/components/ui";
import { Button } from "@hiltermann/components/form";
import ImageWithFallback from "@/components/shared/ImageWithFallback";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { useButtonTarget } from "@/hooks/useButtonTarget";

type Props = GetDynamicContentItemProps<"CallToActionBlock">;

const CallToActionBlock: React.FC<Props> = ({
  image,
  text,
  button_text,
  button_target,
  className,
  variant,
}) => {
  const gradient = variant === "light" ? "gradient-light" : "gradient-1";
  const textColor = variant === "light" ? "text-black" : "text-white";
  const buttonVariant = variant === "light" ? "secondary" : "tertiary";
  const { type, href, onClick, isValid } = useButtonTarget(button_target?.[0]);
  return (
    <section
      data-block="CallToActionBlock"
      className={`relative ${gradient} tablet:h-[176px] flex justify-center ${className ?? ""}`}
    >
      <Grid className="h-full">
        <div className="tablet:flex-row col-span-full row-span-1 row-start-1 flex flex-col items-center justify-center">
          <div className="tablet:block relative hidden h-full w-[208px]">
            <div className="absolute bottom-0 inline-flex">
              <ImageWithFallback
                src={image?.[0]?.ctaRectangle || ""}
                alt={image?.[0]?.description || ""}
                height="256"
                width="208"
                className="mr-14"
              />
            </div>
          </div>
          <div className="tablet:flex-row flex flex-col items-center justify-center">
            <span
              className={`text-p tablet:mr-14 tablet:text-intro mx-4 mb-6 mt-8 text-center font-serif tracking-wider ${textColor}`}
            >
              {text}
            </span>
            {button_text && isValid && (
              <Button
                variant={buttonVariant}
                type={type}
                href={href}
                onClick={onClick}
                className="tablet:mb-0 mb-8 h-fit"
              >
                {button_text}
              </Button>
            )}
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default CallToActionBlock;
