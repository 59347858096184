"use client";

import { HTMLAttributes, ReactNode, useId, useState } from "react";
import clsx from "clsx";
import { ChevronDown } from "../../icons";

export interface AccordionProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactNode;
}

export const Accordion = ({ children, ...props }: AccordionProps) => {
  return (
    <ul className={clsx(props.className)} {...props}>
      {children}
    </ul>
  );
};

interface AccordionItemProps {
  title: ReactNode | string;
  children?: ReactNode;
  icon?: ReactNode;
  isExpanded?: boolean;
}

export const AccordionItem = ({
  title,
  children,
  icon,
  isExpanded: controlledExpanded,
}: AccordionItemProps) => {
  const [isExpanded, setExpanded] = useState(controlledExpanded || false);

  const id = useId();

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <li className="border-grey-light border-b">
      <h2>
        <button
          id={`accordion--id_${id}`}
          aria-expanded={isExpanded}
          aria-controls={`accordion-item--id_${id}`}
          onClick={handleClick}
          type="button"
          className="flex w-full items-center justify-between gap-5 py-4"
          data-testid="accordion-title"
        >
          <span className="flex gap-2">
            {icon}
            <span
              className={clsx(
                "text-label-sm tablet:text-label font-sans font-semibold tracking-wider  block text-left",
                isExpanded && "text-secondary-aubergine"
              )}
            >
              {title}
            </span>
          </span>
          <ChevronDown
            aria-hidden="true"
            role="img"
            width={24}
            className={clsx(
              "transition-1 duration-100 mr-2",
              isExpanded ? "fill-secondary-aubergine rotate-180" : "rotate-0"
            )}
          />
        </button>
      </h2>
      {children && (
        <div
          role="region"
          aria-labelledby={`accordion--id_${id}`}
          aria-hidden={!isExpanded}
          className={clsx(
            "overflow-hidden transition-all grid grid-rows-[0fr] duration-100 ease-in-out",
            isExpanded && "grid grid-rows-[1fr] pb-5"
          )}
        >
          <article className="overflow-hidden">{children}</article>
        </div>
      )}
    </li>
  );
};
